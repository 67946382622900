<template>
    <div>
        <b-row>
            <b-breadcrumb class="col-md-10">
                <b-breadcrumb-item href="/">
                    <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
                    Home
                </b-breadcrumb-item>
                <b-breadcrumb-item href="/abbonamenti">Scelta Operatore</b-breadcrumb-item>
                <b-breadcrumb-item active>
                    ATM Milano
                </b-breadcrumb-item>
            </b-breadcrumb>
            <b-col class="col-md-2">
                <b-img :src="`${apiPath}/abbonamenti/atm.jpg`" alt="ATM Milano" class="float-right " style="max-height:50px; align: middle"></b-img>
            </b-col>
        </b-row>
        
            <b-tabs content-class="mt-3" v-model="activeTab">
                <!-- step-1: selezione / calcolo dell'abbonamento-->
                <b-tab title="Tratta" id="step-1" value="step-1" card>
                    <h2>Il Tuo Abbonamento</h2>
                    <b-form ref="form-1">
                    <b-row>
                        <b-col cols="6">
                            <p></p>
                            <p>
                                Qui trovi la
                                <a href="https://nuovosistematariffario.atm.it/" target="_blank">mappa del Sistema Tariffario Integrato del Bacino di Mobilità (STIBM)</a>.
                            </p>
                        </b-col>
                    </b-row>
                    <!-- calcola abbonamento -->
                    <div class="row">
                        <div class="col-md-4 border-right">
                            <h4 class="text-center">
                                Calcola l'abbonamento in base al tuo tragitto
                            </h4>
                            <span class="text-muted">
                                <b-icon icon="exclamation-circle-fill" variant="success"></b-icon>
                                Per l'urbano di Milano scegli come origine e destinazione Milano</span>
                            <b-form-group required>
                                <!--manca la definizione del metodo per il calcolo dell'abbonamento -->
                                <label for="origine">Da quale città parti?</label>
                                <v-select
									autocomplete="off"
									v-model="form.origine"
									:options="comuni" 
									:reduce="comune => comune.name"	
									label="name"			
									placeholder="Inserici il comune da cui parti"
                                    id="origine"
									name="origine"
                                    class="mb-2"
                                    required/>

                                <label for="destinazione">Qual è la tua città di destinazione?</label>
                                
                                <v-select
									autocomplete="off"
									v-model="form.destinazione"
									:options="comuni" 
									:reduce="comune => comune.name"	
									label="name"			
									placeholder="Inserici il comune della tua destinazione"
                                    id="destinazione"
									name="destinazione"
                                    class="mb-2"
                                    required/>                                
                            </b-form-group>
                            <br />
                            <b-button variant="primary my-3" @click="calcola_tariffa()">Calcola</b-button>
                        </div>

                        <div class="col-md-4 text-center">
                            <br />
                            <p>
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                Calcola l'abbonamento più adatto in base al tuo tragitto
                            </p>
                            <br />
                            <br />
                            <h3>oppure</h3>
                            <br />
                            <br />
                            <p>
                                scegli la tipologia più adatta dall'elenco
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </p>
                        </div>
                        <!-- seleziona abbonamento dall'elenco-->

                        <div class="col-md-4">
                            <b-overlay :show="loading" rounded="sm">
                                <h4 class="text-center">
                                    Scegli il tuo abbonamento
                                </h4>
                                <b-form-select v-model="form.abbonamento_selezionato" :options="lista_abbonamenti" required></b-form-select>
                                <br /><br />

                                <!-- Qui l'utente indica se possiede la tessera per il TPL, la risposta incide al costo-->
                                <!-- Attenzione, la domanda varia da operatore a opretatore. ATM e Trenord richiedono una tessera specifica per gli abbonamenti convenzionati invece GTT no-->
                                <p>
                                    <b>Si tratta di un nuovo abbonamento oppure di un rinnovo?</b>
                                </p>
                                <b-form-group required>
                                    <b-form-radio v-model="form.tessera_tpl" name="tessera_tpl" :value="1">Nuovo abbonamento</b-form-radio>
                                    <!--:value in questo modo il programma capisce lo "0" come valore e non parola-->
                                    <b-form-radio v-model="form.tessera_tpl" name="tessera_tpl" :value="0">Rinnovo</b-form-radio>
                                </b-form-group>
                            </b-overlay>
                        </div>
                    </div>

                    <!-- end of seleziona abbonamento dall'elenco-->
                    <div class="row mt-4">
                        <!--qui viene visualizzato l'output del calcolo dell'abbonamento-->
                        <div class="col-md-4">
                            <p v-if="perc_carico_utente < 1">
                                Ordina il tuo abbonamento annuale per il trasporto pubblico ad un
                                <b>prezzo scontato</b> grazie al contributo aziendale.
                            </p>
                            <p><b>Non devi anticipare nulla</b>, il costo dell'abbonamento verrà distribuito <b>durante l'anno</b> in 12 trattenute sulla tua busta paga.</p>
                        </div>
                        <div class="col-md-4">
                            <abbonamento-card :abbonamento_selezionato="form.abbonamento_selezionato" :prezzo_pieno="prezzo_pieno" :prezzo_scontato="prezzo_scontato">
                            </abbonamento-card>
                        </div>
                    </div>

                    <b-card-footer class="d-flex flex-row-reverse">
                        <b-button @click="nextTab()" variant="primary" class="">Avanti</b-button>
                    </b-card-footer>
                    <!-- end of calcola abbonamento-->
                    </b-form>
                </b-tab>
                <!-- end of step-1:selezione / calcolo dell'abbonamento-->

                <!-- step-2-->
                <b-tab title="Data Inizio" id="step-2" value="step-2" card>
                    <h2>Data inizio validità</h2>
                    <b-form ref="form-2">
                    <p>Le date per richiedere il tuo abbonamento sono le seguenti</p>
                    <b-list-group>
                        <b-list-group-item variant="primary">
                            <b-row>
                                <b-col cols="10">
                                    <label for="mese_validita">Inizio validità dell'abbonamento</label>
                                    <b-form-checkbox v-model="form.mese_validita" :value="prossimo_to_YMD" required name="mese_validita">
                                        1 {{ num_to_mese(scegli_prossimo.validita_abbonamento) }}
                                        {{ num_to_anno(scegli_prossimo.validita_abbonamento) }}
                                    </b-form-checkbox>
                                </b-col>
                                <b-col cols="2" class="align-self-center">
                                    <b-button href="#" variant="primary" @click="nextTab" >Avanti</b-button>
                                </b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                    <h4>Tempistiche previste dall'operatore</h4>

                    <div class="row">
                        <div class="col-md-6">
                            <b-table striped hover :items="validita" :fields="['ordina_entro_il', 'validita_abbonamento']">
                                <template #cell(ordina_entro_il)="data">
                                    {{ data.item.ordina_entro_il_gg }}
                                    {{ num_to_mese(data.item.ordina_entro_il_mm) }}
                                    {{ num_to_anno(data.item.ordina_entro_il_mm) }}
                                </template>
                                <!-- A custom formatted column -->
                                <template #cell(validita_abbonamento)="data">
                                    1 {{ num_to_mese(data.value) }}
                                    {{ Math.max(num_to_anno(data.value), num_to_anno(data.item.ordina_entro_il_mm)) }}
                                </template>
                            </b-table>
                        </div>
                        <div class="col-md-6"></div>
                    </div>
                    </b-form>

                    <b-card-footer class="d-flex flex-row-reverse">
                        <b-button href="#" variant="primary" @click="nextTab()" >Avanti</b-button>
                    </b-card-footer>
                    
                </b-tab>
                <!-- end of step-2-->

                <!-- step-3: dati aggiuntivi -->
                <b-tab title="I tuoi dati" id="step-3" value="step-3">
                    <b-form ref="form-3">
                        <abbonamento-user :form="form"></abbonamento-user>
                    </b-form>
                    <b-card-footer class="d-flex flex-row-reverse">
                        <b-button variant="primary"  @click="nextTab()">Avanti</b-button>
                    </b-card-footer>
                </b-tab>
                <!-- end of step-3 nuovo abbonamento -->

                <!-- step-4: riepilogo e submit-->
                <b-tab title="Riepilogo" id="step-4" value="step-4">
                    <b-form ref="form-3">
                      <abbonamento-riepilogo :form="form" :prezzo_pieno="prezzo_pieno" :prezzo_scontato="prezzo_scontato"></abbonamento-riepilogo>
                    </b-form>
                    <b-card-footer class="d-flex flex-row-reverse">
                        <b-button variant="primary" class="mr-2  float-right" @click.prevent="submit()">
                            <div v-if="loading">
                                <b-spinner small></b-spinner>
                                <span class="sr-only">Sto salvando...</span>
                            </div>
                            <div v-else>
                                <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>
                                Invia la richiesta (acquista)
                            </div>
                        </b-button>
                    </b-card-footer>
                </b-tab>
                <!-- end of step-4: riepilogo e submit-->
            </b-tabs>
        <!-- end of acquisto abbonamento-->
    </div>
</template>

<script>
import Util from "@/mixing/util";
import MixinAbbonamenti from "../mixin.js";
import comuni_mi from "../data/comuni-mi";
import AbbonamentoCard from "../components/AbbonamentoCard";
import AbbonamentoUser from "../components/AbbonamentoUser";
import AbbonamentoRiepilogo from "../components/AbbonamentoRiepilogo";
import vSelect from "vue-select";

import axios from "axios";


export default {
	name: "Abbonamenti",
	components: {
		AbbonamentoCard,
		AbbonamentoUser,
		AbbonamentoRiepilogo,
		vSelect,
	},
	mixins: [Util, MixinAbbonamenti],
	data() {
		return {						
			comuni: comuni_mi,		
		};
	},
	async created() {
		await this.fetchTplOperator("atm");
		this.form.tessera_tpl = 0;
	},
	computed: {
		abbonamento_tariff_id: function() {
			return this.tariffe[this.form.abbonamento_selezionato]["abbconvenzione.tariff_id"];
		},
		prezzo_pieno: function() {
			//console.log(this.form.abbonamento_selezionato);
			if (this.form.abbonamento_selezionato == "1urb_UrbanoMilano") {
				//@ioanna 2 Maggio 2023 - toglie questo Sull'urbano vengono aggiunti 5 euro di costi amministrativi (che equivalgono al costo tessera)
				return this.prezzo_abbonamento; // + parseInt(this.prezzo_tessera);
			} else {
				return this.prezzo_abbonamento + parseInt(this.form.tessera_tpl) * this.prezzo_tessera;
			}
		},
		lista_abbonamenti: function() {
			let k = Object.keys(this.tariffe);
			return k;
		},
	},
	methods: {
		onReset(evt) {
			evt.preventDefault();
			// Reset our form values
			//this.form.email = ''
			//this.form.name = ''
		},
		
		calcola_tariffa() {
			let origine = this.comuni.find(x => x.name == this.form.origine);
			let destinazione = this.comuni.find(x => x.name == this.form.destinazione);

			if (this.form.origine != null && this.form.destinazione != null) {
				if (this.form.origine == "Milano" && this.form.destinazione == "Milano") {
					this.form.abbonamento_selezionato = "1urb_UrbanoMilano";
					return;
				} else if (this.form.origine == this.form.destinazione) {
					this.form.abbonamento_selezionato = origine.zona + "-" + destinazione.zona;
				} else {
					//Sorgente http://www.agenziatpl.it/calcola-tariffa-stibm/www/
					let url = "https://europe-west1-stibm-1561387563826.cloudfunctions.net/";
					this.loading = true;

					var datetime = new Date().toISOString();
					var urlRequest =
                        url + "get_directions_data?origin=" + encodeURI(origine.name) + "&destination=" + encodeURI(destinazione.name) + "&departure_time=" + encodeURI(datetime);
					urlRequest = urlRequest.replace("+", "PLUS");

					axios.get(urlRequest).then(
						result => {
							this.form.abbonamento_selezionato = result.data[0].fare.zone_id;
							this.loading = false;
						},
						error => {
							this.showError(error);
							this.loading = false;
						}
					);

					return;
				}
			}
		},
	},
};
</script>

<style>
.card-img-top {
    padding: 1em;
}
</style>
