import DashboardLayout from "~theme/DashboardLayout.vue";

const routes = [
	{
		path: "/",
		component: DashboardLayout,
		redirect: "/dashboard",
		children: [
			{
				path: "/abbonamenti",
				name: "Sportello Abbonamenti",
				meta: { authorize: ["admin", "sportello"] },
				// lazy-loaded
				component: pageComponent("index"),
			},
			{
				path: "/abbonamenti/riepilogo",
				name: "Le tue Richieste",
				meta: { authorize: ["admin", "sportello"] },
				// lazy-loaded
				component: pageComponent("riepilogo"),
			},
			{
				path: "/abbonamenti/gtt",
				name: "Sportello Abbonamenti GTT",

				meta: {},
				// lazy-loaded
				component: pageComponent("gtt"),
			},
			{
				path: "/abbonamenti/trenord",
				name: "Sportello Abbonamenti Trenord",

				meta: {},
				// lazy-loaded
				component: pageComponent("trenord"),
			},
			{
				path: "/abbonamenti/trenordv2",
				name: "Sportello Abbonamenti Trenord V2",

				meta: {},
				// lazy-loaded
				component: pageComponent("trenordv2"),
			},
			{
				path: "/abbonamenti/atm",
				name: "Accesso Sportello ATM",

				meta: {},
				// lazy-loaded
				component: pageComponent("atm"),
			},
			{
				path: "/abbonamenti/atac",
				name: "Accesso Sportello ATAC",

				meta: {},
				// lazy-loaded
				component: pageComponent("atac"),
			},
			{
				path: "/abbonamenti/ctm",
				name: "Accesso Sportello CTM",

				meta: {},
				// lazy-loaded
				component: pageComponent("ctm"),
			},			
			{
				path: "/abbonamenti/arriva",
				name: "Accesso Sportello Arriva",

				meta: {},
				// lazy-loaded
				component: pageComponent("arriva"),
			},
			{
				path: "/abbonamenti/trenitalia",
				name: "Accesso Sportello Trenitalia",

				meta: {},
				// lazy-loaded
				component: pageComponent("trenitalia"),
			},
        	],
			},
			//Questi elementi usano il template di default
			{
				path: "/abbonamenti/jointly",
				name: "Accesso Login Jointly",

				meta: {},
				// lazy-loaded
				component: pageComponent("jointly"),
			},
			{
				path: "/abbonamenti/ah",
				name: "Accesso Login Airport Handling",

				meta: {},
				// lazy-loaded
				component: pageComponent("ah"),
			},
			{
				path: "/abbonamenti/giuliani",
				name: "Accesso Sportello Abbonamenti Giuliani Pharma",

				meta: {},
				// lazy-loaded
				component: pageComponent("giuliani"),
			},
			{
				path: "/abbonamenti/azimut",
				name: "Accesso Sportello Azimut Benetti SPA",

				meta: {},
				// lazy-loaded
				component: pageComponent("azimut"),
			},
			{
				path: "/abbonamenti/sky",
				name: "Accesso Sportello Sky Italia",

				meta: {},
				// lazy-loaded
				component: pageComponent("sky"),
			},
			{
				path: "/abbonamenti/trenord/login",
				name: "Accesso Portale Trenord",

				meta: {},
				// lazy-loaded
				component: pageComponent("trenord-login"),
			},
		];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
 * **/
function pageComponent(name) {	
	var res;	
	try {
		res = require("@/extra/Abbonamenti/pages/" + name + ".vue").default;
	} catch (e) {
		//console.log("Errore route");
		res = require("./pages/" + name + ".vue").default;
	}	
	return res;
}

export default routes;