<template>
    <div class="container bg-white p-3">
        <template>
            <b-breadcrumb :items="items"></b-breadcrumb>
        </template>
        <b-row>
            <b-col>
                <h1>Privacy Policy</h1>
                <h2>Titolare del Trattamento</h2>
                <p>Il Titolare del Trattamento dei dati &egrave; l'azienda committente a cui fanno riferimento i
                    lavoratori o
                    gli studenti coinvolti.</p>
                <h2>Finalit&agrave; del Trattamento</h2>
                <p>I dati caricati sulla piattaforma saranno trattati per il perseguimento delle seguenti
                    finalit&agrave;:<br />&bull; servizio di redazione del Piano Spostamenti Casa-Lavoro
                    (PSCL);<br />&bull;
                    erogazione e compilazione del questionario sulle abitudini di spostamento casa-lavoro dei
                    dipendenti;<br />&bull; elaborazione dell&rsquo;analisi di accessibilit&agrave;, ivi compreso
                    trattamento di
                    dati personali (non particolari) di dipendenti e collaboratori coinvolti.</p>
                <p>Il fine dell&rsquo;indagine &egrave; di raccogliere informazioni sullo spostamento casa-lavoro dei
                    dipendenti, come l&rsquo;origine e la destinazione, le modalit&agrave; di spostamento (mezzi
                    utilizzati), la
                    frequenza dello spostamento, ecc., con lo scopo di integrare nel PSCL ulteriori politiche virtuose
                    sul tema
                    della mobilit&agrave; e quindi promuovere la mobilit&agrave; sostenibile a livello aziendale.</p>
                <p>Verranno trattati i seguenti dati:</p>
                <ul>
                    <li>et&agrave;</li>
                    <li>indirizzo di origine dello spostamento casa-lavoro</li>
                    <li>indirizzo del luogo di lavoro</li>
                    <li>frequenza dello spostamento casa-lavoro</li>
                    <li>durata dello spostamento casa-lavoro</li>
                    <li>distanza dello spostamento casa-lavoro</li>
                    <li>spostamenti accessori legati allo spostamento casa-lavoro</li>
                    <li>costo mensile dello spostamento casa-lavoro</li>
                    <li>mezzo utilizzato per lo spostamento casa-lavoro</li>
                    <li>modalit&agrave; di lavoro, es. smart working, turni, straordinario ecc.</li>
                </ul>
                <h2>Responsabile del Trattamento</h2>
                <p>Il trattamento sar&agrave; svolto dalla MobilitySquare Srl fino a revoca o conclusione del contratto
                    principale.</p>
                <h2>Utilizzo dei dati</h2>
                <p>Il Responsabile del Trattamento utilizzer&agrave; i dati personali soltanto su istruzione documentata
                    del
                    Titolare, anche in caso di trasferimento di dati personali verso un paese terzo o
                    un&rsquo;organizzazione
                    internazionale, salvo che lo richieda una disposizione normativa.</p>
                <h2>Accessi autorizzati</h2>
                <p>Il Responsabile del Trattamento garantisce che le persone autorizzate al trattamento dei dati
                    personali siano
                    impegnate alla riservatezza o abbiano un adeguato obbligo legale di riservatezza.</p>
                <h2>Misure</h2>
                <p>Il Responsabile del Trattamento adotta tutte le misure richieste ai sensi dell&rsquo;art. 32 del
                    Reg.UE
                    679/2016.</p>
                <p>Il Responsabile del Trattamento si impegna ad assistere il Titolare del Trattamento con misure
                    tecniche e
                    organizzative adeguate, nella misura in cui ci&ograve; sia possibile, al fine di soddisfare
                    l'obbligo del
                    Titolare del Trattamento di dare seguito alle richieste per l'esercizio dei diritti
                    dell'interessato.</p>
                <p>Il Responsabile del Trattamento si impegna ad assistere il Titolare del Trattamento nel garantire il
                    rispetto
                    degli obblighi di cui agli articoli da 32 a 36 del Regolamento, tenendo conto della natura del
                    trattamento e
                    delle informazioni a sua disposizione.</p>
                <p>Il Responsabile del Trattamento, al termine della prestazione contrattuale, si impegna a restituire
                    tutti i
                    dati personali relativi ai trattamenti effettuati, nonch&eacute; a cancellare le copie esistenti,
                    salvo il
                    caso in cui la conservazione da parte del Responsabile sia prevista dal diritto dell'UE o degli
                    Stati
                    membri. Non saranno eliminati i dati aggregati che non consento di risalire
                    all&rsquo;identit&agrave; dei
                    singoli lavoratori.</p>
                <p>Il Responsabile del Trattamento metter&agrave; a disposizione del Titolare del Trattamento tutte le
                    informazioni necessarie per dimostrare il rispetto degli obblighi dell&rsquo;art.28 Reg.UE 679/2016
                    e
                    consentir&agrave; e contribuir&agrave; alle attivit&agrave; di revisione, comprese le ispezioni,
                    realizzate
                    dal Titolare del Trattamento o da un altro soggetto da questi incaricato.</p>
                <p>Il Responsabile del Trattamento informer&agrave; immediatamente il Titolare del Trattamento qualora,
                    a suo
                    parere, un'istruzione ricevuta violi il Regolamento UE/679/2016 o altre disposizioni, nazionali o
                    dell'UE,
                    relative alla protezione dei dati.</p>
                <p>I dati trattati elettronicamente saranno sempre ospitati su server posizionati all&rsquo;interno
                    dell&rsquo;UE, dotati delle consuete ed aggiornate misure di sicurezza (accessi SSH, firewall,
                    antivirus).
                    La trasmissione dei dati tra l&rsquo;utilizzatore ed il sistema avverr&agrave; sempre su protocollo
                    criptato
                    (https). Solo gli utenti dotati di opportune autorizzazioni possono accedere ai dati.</p>
                <p>Contatto in caso di Data Breach: moma@mobilitysquare.eu</p>
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    name: "Privacy",
    data() {
        return {
            items: [
                {
                    text: "Home",
                    href: "/",
                },
                {
                    text: "Privacy",
                    active: true,
                },
            ],
        };
    },
};
</script>
