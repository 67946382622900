<template>
    <div class="page-holder bg-cover d-flex flex-column sticky-footer-wrapper cover-back">
        <b-container class="flex-fill">
            <b-card class="overflow-hidden mt-5" footer-tag="footer"
                :class="logo ? 'col-md-8 offset-md-2' : 'col-md-6 offset-md-3'">
                <b-overlay :show="loading" rounded="sm">
                    <div>
                        <h1>Attivazione Account o Reset Password</h1>

                        <h4>Privacy: <span class="small">Consenso al trattamento dei dati</span></h4>
                        <p>
                            I dati forniti alla piattaforma Mobility48 saranno gestiti da MobilitySquare solo per i
                            servizi di
                            mobilità attivati di volta in volta dall'utente (es:
                            Sportello Abbonamenti). <br />
                            L'utente prende atto che per lo svolgimento dell'attività, i dati raccolti dovranno essere
                            comunicati ai seguenti soggetti:
                        </p>
                        <ul>
                            <li>Operatori del Trasporto Pubblico Locale scelti dall'utente</li>
                            <li>Azienda a cui appartiene l'utente - Ufficio Paghe e Ufficio del Personale</li>
                        </ul>
                        <a href="https://mobilitysquare.eu/privacy" class="text-muted float-right small">Privacy Policy
                            MobilitySquare</a>

                        <form id="loginForm" class="form-horizontal" accept-charset="utf-8"
                            @submit.prevent="onSubmit()">
                            <b-form-checkbox v-model="privacy" required name="privacy" id="privacy"> Ho preso atto e
                                voglio
                                procedere</b-form-checkbox>
                            <b-row class="my-2">
                                <div class="col">
                                <div class="form-group">
                                    <label class="col-12 control-label required" for="email">
                                        Inserisci la tua mail aziendale per
                                        resettare la password o attivare l'account
                                    </label>

                                    <input name="email" placeholder="Email" class="form-control" maxlength="100"
                                        type="text" id="email" required="required" v-model="email" />

                                </div>
                                <div class="form-group">
                                    <label class="col-12 control-label" for="badge">
                                        Inserisci la tua matricola (numero di badge)
                                    </label>


                                    <input name="matricola" placeholder="Inserisci la matricola, se non la sai scrivi NO."
                                        class="form-control" maxlength="10" type="text" id="matricola"
                                        v-model="matricola" />
                                </div>
                            </div>
                            </b-row>
                            <b-row class="mt-5">
                                <b-col cols="4">
                                    <input class="btn btn-success ml-auto" id="loginSubmitBtn" type="submit"
                                        value="Inviami il link per cominciare" />
                                </b-col>
                                <b-col cols="8">
                                    <b-alert :show="message != ''">{{ message }}</b-alert>
                                </b-col>
                            </b-row>
                        </form>

                    </div>
                </b-overlay>
            </b-card>
        </b-container>
    </div>
</template>

<script>
import AbbonamentiService from "../abbonamenti.service";

export default {
    name: "SkyLogin",
    data() {
        return {
            email: "",
            loading: false,
            message: "",
            privacy: false,
            matricola: "",
            logo: process.env.VUE_APP_ICON,
        };
    },
    methods: {
        onSubmit() {
            this.loading = true;
            if (!this.privacy) {
                this.message = "Devi accettare la privacy per proseguire";
                this.loading = false;
                return;
            }
            var self = this;
            let company_id = 1698; //Sky Italia

            AbbonamentiService.registerSportello(this.email, company_id, this.matricola)
                .then((result) => (self.message = result.data.msg))
                .catch(() => (self.message = "Errore durante la registrazione dell'utente, puoi riprovare?"));

            this.loading = false;
        }, //onSubmit
    }, //methods
};
</script>
<style scoped>
.login-outer-box {
    padding: 60px 30px 40px 20px;
    background: #f49537;
    border-radius: 10px;
}

.login-inner-box {
    padding: 30px;
    background: #212025;
    border-radius: 10px;
    color: #fff;
}

#logo_ape {
    height: 100px;
    margin-top: -80px;
}
</style>
